import React from "react"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { SlugLink } from '../components/utils'
import { Button } from 'react-bootstrap'
class Tags extends React.Component {
  render() {
    const { data, location, pageContext } = this.props;
    console.log("tags", location);
    // if (location != null) {
    //   if (location.href === 'https://www.balldream.com/tags/') {
    //     window.location.href = 'https://www.balldream.com/tags';
    //   }
    // }
    return (
      <Layout location={location} title={data.title}>
        <SEOTAG
          title={`All Tags`}
          description={`All Tags - ${data.domainName}`}
        // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div className="w-grid__columns w-grid__columns--one">
          <div>
            <h1>Tags</h1>
            {/* <h2>{location.href}</h2> */}
            {data.privateGraphql.tags.map((tag, index) => (
              <Link key={index} to={`/${(tag.slugname)}`}>
                <Button variant="dark" style={{ margin: 2 }}>{tag.name}</Button>
              </Link>
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}
export default Tags
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        domainName
      }
    }
    privateGraphql {
        tags(limit:2000,skip:0,siteId: 1051) {
          id
          name
          slugname
        }
      }
  }
`